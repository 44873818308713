/*!
 * Bootstrap Docs (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under the Creative Commons Attribution 3.0 Unported License. For
 * details, see https://creativecommons.org/licenses/by/3.0/.
 */
/*
 * Bootstrap Documentation
 * Special styles for presenting Bootstrap's documentation and code examples.
 */
/*
 * Scaffolding
 *
 * Update the basics of our documents to prep for docs content.
 */
body {
  position: relative;
  /* For scrollspy */ }

/* Keep code small in tables on account of limited space */
.table code {
  font-size: 13px;
  font-weight: normal; }

/* Inline code within headings retain the heading's background-color */
h2 code,
h3 code,
h4 code {
  background-color: inherit; }

/* Outline button for use within the docs */
.btn-outline {
  color: #563d7c;
  background-color: transparent;
  border-color: #563d7c; }

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  color: #fff;
  background-color: #563d7c;
  border-color: #563d7c; }

/* Inverted outline button (white on dark) */
.btn-outline-inverse {
  color: #fff;
  background-color: transparent;
  border-color: #cdbfe3; }

.btn-outline-inverse:hover,
.btn-outline-inverse:focus,
.btn-outline-inverse:active {
  color: #563d7c;
  text-shadow: none;
  background-color: #fff;
  border-color: #fff; }

/* Bootstrap "B" icon */
.bs-docs-booticon {
  display: block;
  font-weight: 500;
  color: #fff;
  text-align: center;
  cursor: default;
  background-color: #563d7c;
  border-radius: 15%; }

.bs-docs-booticon-sm {
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 28px; }

.bs-docs-booticon-lg {
  width: 144px;
  height: 144px;
  font-size: 108px;
  line-height: 140px; }

.bs-docs-booticon-inverse {
  color: #563d7c;
  background-color: #fff; }

.bs-docs-booticon-outline {
  background-color: transparent;
  border: 1px solid #cdbfe3; }

/*
 * Fancy skip link
 *
 * Make it look a bit less "bare bones"
 * Also includes focus suppression for the Chrome tabindex="-1" workaround
 */
#skippy {
  display: block;
  padding: 1em;
  color: #fff;
  background-color: #6f5499;
  outline: 0; }

#skippy .skiplink-text {
  padding: .5em;
  outline: 1px dotted; }

#content:focus {
  outline: none; }

/*
 * Main navigation
 *
 * Turn the `.navbar` at the top of the docs purple.
 */
.bs-docs-nav {
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0; }

.bs-home-nav .bs-nav-b {
  display: none; }

.bs-docs-nav .navbar-brand,
.bs-docs-nav .navbar-nav > li > a {
  font-weight: 500;
  color: #563d7c; }

.bs-docs-nav .navbar-nav > li > a:hover,
.bs-docs-nav .navbar-nav > .active > a,
.bs-docs-nav .navbar-nav > .active > a:hover {
  color: #463265;
  background-color: #f9f9f9; }

.bs-docs-nav .navbar-toggle .icon-bar {
  background-color: #563d7c; }

.bs-docs-nav .navbar-header .navbar-toggle {
  border-color: #fff; }

.bs-docs-nav .navbar-header .navbar-toggle:hover,
.bs-docs-nav .navbar-header .navbar-toggle:focus {
  background-color: #f9f9f9;
  border-color: #f9f9f9; }

/*
 * Footer
 *
 * Separated section of content at the bottom of all pages, save the homepage.
 */
.bs-docs-footer {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 100px;
  color: #99979c;
  text-align: center;
  background-color: #2a2730; }

.bs-docs-footer a {
  color: #fff; }

.bs-docs-footer-links {
  padding-left: 0;
  margin-bottom: 20px; }

.bs-docs-footer-links li {
  display: inline-block; }

.bs-docs-footer-links li + li {
  margin-left: 15px; }

@media (min-width: 768px) {
  .bs-docs-footer {
    text-align: left; }
  .bs-docs-footer p {
    margin-bottom: 0; } }

/*
 * Homepage
 *
 * Tweaks to the custom homepage and the masthead (main jumbotron).
 */
/* Share masthead with page headers */
.bs-docs-masthead,
.bs-docs-header {
  position: relative;
  padding: 30px 0;
  color: #cdbfe3;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #6f5499;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#563d7c), to(#6f5499));
  background-image: -webkit-linear-gradient(top, #563d7c 0%, #6f5499 100%);
  background-image: linear-gradient(to bottom, #563d7c 0%, #6f5499 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#563d7c', endColorstr='#6F5499', GradientType=0);
  background-repeat: repeat-x; }

/* Masthead (headings and download button) */
.bs-docs-masthead .bs-docs-booticon {
  margin: 0 auto 30px; }

.bs-docs-masthead h1 {
  font-weight: 300;
  line-height: 1;
  color: #fff; }

.bs-docs-masthead .lead {
  margin: 0 auto 30px;
  font-size: 20px;
  color: #fff; }

.bs-docs-masthead .version {
  margin-top: -15px;
  margin-bottom: 30px;
  color: #9783b9; }

.bs-docs-masthead .btn {
  width: 100%;
  padding: 15px 30px;
  font-size: 20px; }

@media (min-width: 480px) {
  .bs-docs-masthead .btn {
    width: auto; } }

@media (min-width: 768px) {
  .bs-docs-masthead {
    padding: 80px 0; }
  .bs-docs-masthead h1 {
    font-size: 60px; }
  .bs-docs-masthead .lead {
    font-size: 24px; } }

@media (min-width: 992px) {
  .bs-docs-masthead .lead {
    width: 80%;
    font-size: 30px; } }

/*
 * Page headers
 *
 * Jumbotron-esque headers at the top of every page that's not the homepage.
 */
/* Page headers */
.bs-docs-header {
  margin-bottom: 40px;
  font-size: 20px; }

.bs-docs-header h1 {
  margin-top: 0;
  color: #fff; }

.bs-docs-header p {
  margin-bottom: 0;
  font-weight: 300;
  line-height: 1.4; }

.bs-docs-header .container {
  position: relative; }

@media (min-width: 768px) {
  .bs-docs-header {
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 24px;
    text-align: left; }
  .bs-docs-header h1 {
    font-size: 60px;
    line-height: 1; } }

@media (min-width: 992px) {
  .bs-docs-header h1,
  .bs-docs-header p {
    margin-right: 380px; } }

/*
 * Carbon ads
 *
 * Single display ad that shows on all pages (except homepage) in page headers.
 * The hella `!important` is required for any pre-set property.
 */
.carbonad {
  width: auto !important;
  height: auto !important;
  padding: 20px !important;
  margin: 30px -15px -31px !important;
  overflow: hidden;
  /* clearfix */
  font-size: 13px !important;
  line-height: 16px !important;
  text-align: left;
  background: transparent !important;
  border: solid #866ab3 !important;
  border-width: 1px 0 !important; }

.carbonad-img {
  margin: 0 !important; }

.carbonad-text,
.carbonad-tag {
  display: block !important;
  float: none !important;
  width: auto !important;
  height: auto !important;
  margin-left: 145px !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.carbonad-text {
  padding-top: 0 !important; }

.carbonad-tag {
  color: inherit !important;
  text-align: left !important; }

.carbonad-text a,
.carbonad-tag a {
  color: #fff !important; }

.carbonad #azcarbon > img {
  display: none;
  /* hide what I assume are tracking images */ }

@media (min-width: 480px) {
  .carbonad {
    width: 330px !important;
    margin: 20px auto !important;
    border-width: 1px !important;
    border-radius: 4px; }
  .bs-docs-masthead .carbonad {
    margin: 50px auto 0 !important; } }

@media (min-width: 768px) {
  .carbonad {
    margin-right: 0 !important;
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .carbonad {
    position: absolute;
    top: 0;
    right: 15px;
    /* 15px instead of 0 since box-sizing */
    width: 330px !important;
    padding: 15px !important;
    margin: 0 !important; }
  .bs-docs-masthead .carbonad {
    position: static; } }

/*
 * Homepage featurettes
 *
 * Reasons to use Bootstrap, entries from the Expo, and more.
 */
.bs-docs-featurette {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 16px;
  line-height: 1.5;
  color: #555;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5; }

.bs-docs-featurette + .bs-docs-footer {
  margin-top: 0;
  border-top: 0; }

.bs-docs-featurette-title {
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: normal;
  color: #333; }

.half-rule {
  width: 100px;
  margin: 40px auto; }

.bs-docs-featurette h3 {
  margin-bottom: 5px;
  font-weight: normal;
  color: #333; }

.bs-docs-featurette-img {
  display: block;
  margin-bottom: 20px;
  color: #333; }

.bs-docs-featurette-img:hover {
  color: #337ab7;
  text-decoration: none; }

.bs-docs-featurette-img img {
  display: block;
  margin-bottom: 15px; }

@media (min-width: 480px) {
  .bs-docs-featurette .img-responsive {
    margin-top: 30px; } }

@media (min-width: 768px) {
  .bs-docs-featurette {
    padding-top: 100px;
    padding-bottom: 100px; }
  .bs-docs-featurette-title {
    font-size: 40px; }
  .bs-docs-featurette .lead {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto; }
  .bs-docs-featurette .img-responsive {
    margin-top: 0; } }

/*
 * Featured sites
 *
 * Homepage thumbnails from the Expo.
 */
.bs-docs-featured-sites {
  margin-right: -1px;
  margin-left: -1px; }

.bs-docs-featured-sites .col-xs-6 {
  padding: 1px; }

.bs-docs-featured-sites .img-responsive {
  margin-top: 0; }

@media (min-width: 768px) {
  .bs-docs-featured-sites .col-sm-3:first-child img {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .bs-docs-featured-sites .col-sm-3:last-child img {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; } }

/*
 * Examples
 *
 * Linked docs examples.
 */
.bs-examples .thumbnail {
  margin-bottom: 10px; }

.bs-examples h4 {
  margin-bottom: 5px; }

.bs-examples p {
  margin-bottom: 20px; }

@media (max-width: 480px) {
  .bs-examples {
    margin-right: -10px;
    margin-left: -10px; }
  .bs-examples > [class^="col-"] {
    padding-right: 10px;
    padding-left: 10px; } }

/*
 * Side navigation
 *
 * Scrollspy and affixed enhanced navigation to highlight sections and secondary
 * sections of docs content.
 */
/* By default it's not affixed in mobile views, so undo that */
.bs-docs-sidebar.affix {
  position: static; }

@media (min-width: 768px) {
  .bs-docs-sidebar {
    padding-left: 20px; } }

/* First level of nav */
.bs-docs-sidenav {
  margin-top: 20px;
  margin-bottom: 20px; }

/* All levels of nav */
.bs-docs-sidebar .nav > li > a {
  display: block;
  padding: 4px 20px;
  font-size: 13px;
  font-weight: 500;
  color: #767676; }

.bs-docs-sidebar .nav > li > a:hover,
.bs-docs-sidebar .nav > li > a:focus {
  padding-left: 19px;
  color: #563d7c;
  text-decoration: none;
  background-color: transparent;
  border-left: 1px solid #563d7c; }

.bs-docs-sidebar .nav > .active > a,
.bs-docs-sidebar .nav > .active:hover > a,
.bs-docs-sidebar .nav > .active:focus > a {
  padding-left: 18px;
  font-weight: bold;
  color: #563d7c;
  background-color: transparent;
  border-left: 2px solid #563d7c; }

/* Nav: second level (shown on .active) */
.bs-docs-sidebar .nav .nav {
  display: none;
  /* Hide by default, but at >768px, show it */
  padding-bottom: 10px; }

.bs-docs-sidebar .nav .nav > li > a {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 30px;
  font-size: 12px;
  font-weight: normal; }

.bs-docs-sidebar .nav .nav > li > a:hover,
.bs-docs-sidebar .nav .nav > li > a:focus {
  padding-left: 29px; }

.bs-docs-sidebar .nav .nav > .active > a,
.bs-docs-sidebar .nav .nav > .active:hover > a,
.bs-docs-sidebar .nav .nav > .active:focus > a {
  padding-left: 28px;
  font-weight: 500; }

/* Back to top (hidden on mobile) */
.back-to-top,
.bs-docs-theme-toggle {
  display: none;
  padding: 4px 10px;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #999; }

.back-to-top:hover,
.bs-docs-theme-toggle:hover {
  color: #563d7c;
  text-decoration: none; }

.bs-docs-theme-toggle {
  margin-top: 0; }

@media (min-width: 768px) {
  .back-to-top,
  .bs-docs-theme-toggle {
    display: block; } }

/* Show and affix the side nav when space allows it */
@media (min-width: 992px) {
  .bs-docs-sidebar .nav > .active > ul {
    display: block; }
  /* Widen the fixed sidebar */
  .bs-docs-sidebar.affix,
  .bs-docs-sidebar.affix-bottom {
    width: 213px; }
  .bs-docs-sidebar.affix {
    position: fixed;
    /* Undo the static from mobile first approach */
    top: 20px; }
  .bs-docs-sidebar.affix-bottom {
    position: absolute;
    /* Undo the static from mobile first approach */ }
  .bs-docs-sidebar.affix-bottom .bs-docs-sidenav,
  .bs-docs-sidebar.affix .bs-docs-sidenav {
    margin-top: 0;
    margin-bottom: 0; } }

@media (min-width: 1200px) {
  /* Widen the fixed sidebar again */
  .bs-docs-sidebar.affix-bottom,
  .bs-docs-sidebar.affix {
    width: 263px; } }

/*
 * Docs sections
 *
 * Content blocks for each component or feature.
 */
/* Space things out */
.bs-docs-section {
  margin-bottom: 60px; }

.bs-docs-section:last-child {
  margin-bottom: 0; }

h1[id] {
  padding-top: 20px;
  margin-top: 0; }

/*
 * Callouts
 *
 * Not quite alerts, but custom and helpful notes for folks reading the docs.
 * Requires a base and modifier class.
 */
/* Common styles for all types */
.bs-callout {
  padding: 10px;
  margin: 20px 0;
  border-left: 5px solid #eee; }

.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px; }

.bs-callout p:last-child {
  margin-bottom: 0; }

.bs-callout code {
  border-radius: 3px; }

/* Tighten up space between multiple callouts */
.bs-callout + .bs-callout {
  margin-top: -5px; }

/* Variations */
.bs-callout-danger {
  border-left-color: #ce4844; }

.bs-callout-danger h4 {
  color: #ce4844; }

.bs-callout-warning {
  border-left-color: #aa6708; }

.bs-callout-warning h4 {
  color: #aa6708; }

.bs-callout-info {
  border-left-color: #DDDDDD; }

.bs-callout-info h4 {
  color: #1b809e; }

/*
 * Color swatches
 *
 * Color swatches and associated values for our grayscale and brand colors.
 */
.color-swatches {
  margin: 0 -5px;
  overflow: hidden;
  /* clearfix */ }

.color-swatch {
  float: left;
  width: 60px;
  height: 60px;
  margin: 0 5px;
  border-radius: 3px; }

@media (min-width: 768px) {
  .color-swatch {
    width: 100px;
    height: 100px; } }

/* Framework colors */
.color-swatches .gray-darker {
  background-color: #222; }

.color-swatches .gray-dark {
  background-color: #333; }

.color-swatches .gray {
  background-color: #555; }

.color-swatches .gray-light {
  background-color: #999; }

.color-swatches .gray-lighter {
  background-color: #eee; }

.color-swatches .brand-primary {
  background-color: #337ab7; }

.color-swatches .brand-success {
  background-color: #5cb85c; }

.color-swatches .brand-warning {
  background-color: #f0ad4e; }

.color-swatches .brand-danger {
  background-color: #d9534f; }

.color-swatches .brand-info {
  background-color: #5bc0de; }

/* Docs colors */
.color-swatches .bs-purple {
  background-color: #563d7c; }

.color-swatches .bs-purple-light {
  background-color: #c7bfd3; }

.color-swatches .bs-purple-lighter {
  background-color: #e5e1ea; }

.color-swatches .bs-gray {
  background-color: #f9f9f9; }

/*
 * Team members
 *
 * Avatars, names, and usernames for core team.
 */
.bs-team .team-member {
  line-height: 32px;
  color: #555; }

.bs-team .team-member:hover {
  color: #333;
  text-decoration: none; }

.bs-team .github-btn {
  float: right;
  width: 180px;
  height: 20px;
  margin-top: 6px;
  border: none; }

.bs-team img {
  float: left;
  width: 32px;
  margin-right: 10px;
  border-radius: 4px; }

/*
 * Wall of Browser Bugs
 *
 * Better display for the responsive table on the Wall of Browser Bugs.
 */
.bs-docs-browser-bugs td p {
  margin-bottom: 0; }

.bs-docs-browser-bugs th:first-child {
  width: 18%; }

/*
 * Grid examples
 *
 * Highlight the grid columns within the docs so folks can see their padding,
 * alignment, sizing, etc.
 */
.show-grid {
  margin-bottom: 15px; }

.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #eee;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid #ddd;
  border: 1px solid rgba(86, 61, 124, 0.2); }

/*
 * Examples
 *
 * Isolated sections of example content for each component or feature. Usually
 * followed by a code snippet.
 */
.bs-example {
  position: relative;
  padding: 45px 15px 15px;
  margin: 0 -15px 15px;
  border-color: #e5e5e5 #eee #eee;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  border-bottom: none; }

/* Echo out a label for the example */
.bs-example:after {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #959595;
  text-transform: uppercase;
  letter-spacing: 1px;
  content: "Example"; }

.bs-example-padded-bottom {
  padding-bottom: 24px; }

/* Tweak display of the code snippets when following an example */
.bs-example + .highlight,
.bs-example + .zero-clipboard + .highlight {
  margin: -15px -15px 15px;
  border-width: 0 0 1px;
  border-radius: 0; }

/* Make the examples and snippets not full-width */
@media (min-width: 768px) {
  .bs-example {
    margin-right: 0;
    margin-left: 0;
    background-color: #fff;
    border-color: #ddd;
    border-width: 1px;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .bs-example + .highlight,
  .bs-example + .zero-clipboard + .highlight {
    margin-top: -16px;
    margin-right: 0;
    margin-left: 0;
    border-width: 1px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
  .bs-example-standalone {
    border-radius: 4px; } }

/* Undo width of container */
.bs-example .container {
  width: auto; }

/* Tweak content of examples for optimum awesome */
.bs-example > p:last-child,
.bs-example > ul:last-child,
.bs-example > ol:last-child,
.bs-example > blockquote:last-child,
.bs-example > .form-control:last-child,
.bs-example > .table:last-child,
.bs-example > .navbar:last-child,
.bs-example > .jumbotron:last-child,
.bs-example > .alert:last-child,
.bs-example > .panel:last-child,
.bs-example > .list-group:last-child,
.bs-example > .well:last-child,
.bs-example > .progress:last-child,
.bs-example > .table-responsive:last-child > .table {
  margin-bottom: 0; }

.bs-example > p > .close {
  float: none; }

/* Typography */
.bs-example-type .table .type-info {
  color: #767676;
  vertical-align: middle; }

.bs-example-type .table td {
  padding: 15px 0;
  border-color: #eee; }

.bs-example-type .table tr:first-child td {
  border-top: 0; }

.bs-example-type h1,
.bs-example-type h2,
.bs-example-type h3,
.bs-example-type h4,
.bs-example-type h5,
.bs-example-type h6 {
  margin: 0; }

/* Contextual background colors */
.bs-example-bg-classes p {
  padding: 15px; }

/* Images */
.bs-example > .img-circle,
.bs-example > .img-rounded,
.bs-example > .img-thumbnail {
  margin: 5px; }

/* Tables */
.bs-example > .table-responsive > .table {
  background-color: #fff; }

/* Buttons */
.bs-example > .btn,
.bs-example > .btn-group {
  margin-top: 5px;
  margin-bottom: 5px; }

.bs-example > .btn-toolbar + .btn-toolbar {
  margin-top: 10px; }

/* Forms */
.bs-example-control-sizing select,
.bs-example-control-sizing input[type="text"] + input[type="text"] {
  margin-top: 10px; }

.bs-example-form .input-group {
  margin-bottom: 10px; }

.bs-example > textarea.form-control {
  resize: vertical; }

/* List groups */
.bs-example > .list-group {
  max-width: 400px; }

/* Navbars */
.bs-example .navbar:last-child {
  margin-bottom: 0; }

.bs-navbar-top-example,
.bs-navbar-bottom-example {
  z-index: 1;
  padding: 0;
  overflow: hidden;
  /* cut the drop shadows off */ }

.bs-navbar-top-example .navbar-header,
.bs-navbar-bottom-example .navbar-header {
  margin-left: 0; }

.bs-navbar-top-example .navbar-fixed-top,
.bs-navbar-bottom-example .navbar-fixed-bottom {
  position: relative;
  margin-right: 0;
  margin-left: 0; }

.bs-navbar-top-example {
  padding-bottom: 45px; }

.bs-navbar-top-example:after {
  top: auto;
  bottom: 15px; }

.bs-navbar-top-example .navbar-fixed-top {
  top: -1px; }

.bs-navbar-bottom-example {
  padding-top: 45px; }

.bs-navbar-bottom-example .navbar-fixed-bottom {
  bottom: -1px; }

.bs-navbar-bottom-example .navbar {
  margin-bottom: 0; }

@media (min-width: 768px) {
  .bs-navbar-top-example .navbar-fixed-top,
  .bs-navbar-bottom-example .navbar-fixed-bottom {
    position: absolute; } }

/* Pagination */
.bs-example .pagination {
  margin-top: 10px;
  margin-bottom: 10px; }

/* Pager */
.bs-example > .pager {
  margin-top: 0; }

/* Example modals */
.bs-example-modal {
  background-color: #f5f5f5; }

.bs-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block; }

.bs-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto; }

/* Example dropdowns */
.bs-example > .dropdown > .dropdown-toggle {
  float: left; }

.bs-example > .dropdown > .dropdown-menu {
  position: static;
  display: block;
  margin-bottom: 5px;
  clear: left; }

/* Example tabbable tabs */
.bs-example-tabs .nav-tabs {
  margin-bottom: 15px; }

/* Tooltips */
.bs-example-tooltips {
  text-align: center; }

.bs-example-tooltips > .btn {
  margin-top: 5px;
  margin-bottom: 5px; }

.bs-example-tooltip .tooltip {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1; }

/* Popovers */
.bs-example-popover {
  padding-bottom: 24px;
  background-color: #f9f9f9; }

.bs-example-popover .popover {
  position: relative;
  display: block;
  float: left;
  width: 260px;
  margin: 20px; }

/* Scrollspy demo on fixed height div */
.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 10px;
  overflow: auto; }

.bs-example > .nav-pills-stacked-example {
  max-width: 300px; }

/* Simple collapse example */
#collapseExample .well {
  margin-bottom: 0; }

/* Don't wrap event names in Events tables in JS plugin docs */
.bs-events-table > thead > tr > th:first-child,
.bs-events-table > tbody > tr > td:first-child {
  white-space: nowrap; }

.bs-events-table > thead > tr > th:first-child {
  width: 150px; }

.js-options-table > thead > tr > th:nth-child(1),
.js-options-table > thead > tr > th:nth-child(2) {
  width: 100px; }

.js-options-table > thead > tr > th:nth-child(3) {
  width: 50px; }

/*
 * Code snippets
 *
 * Generated via Pygments and Jekyll, these are snippets of HTML, CSS, and JS.
 */
.highlight {
  padding: 9px 14px;
  margin-bottom: 14px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 4px; }

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0; }

.highlight pre code {
  font-size: inherit;
  color: #333;
  /* Effectively the base text color */ }

.highlight pre code:first-child {
  display: inline-block;
  padding-right: 45px; }

/*
 * Responsive tests
 *
 * Generate a set of tests to show the responsive utilities in action.
 */
/* Responsive (scrollable) doc tables */
.table-responsive .highlight pre {
  white-space: normal; }

/* Utility classes table  */
.bs-table th small,
.responsive-utilities th small {
  display: block;
  font-weight: normal;
  color: #999; }

.responsive-utilities tbody th {
  font-weight: normal; }

.responsive-utilities td {
  text-align: center; }

.responsive-utilities td.is-visible {
  color: #468847;
  background-color: #dff0d8 !important; }

.responsive-utilities td.is-hidden {
  color: #ccc;
  background-color: #f9f9f9 !important; }

/* Responsive tests */
.responsive-utilities-test {
  margin-top: 5px; }

.responsive-utilities-test .col-xs-6 {
  margin-bottom: 10px; }

.responsive-utilities-test span {
  display: block;
  padding: 15px 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.1;
  text-align: center;
  border-radius: 4px; }

.visible-on .col-xs-6 .hidden-xs,
.visible-on .col-xs-6 .hidden-sm,
.visible-on .col-xs-6 .hidden-md,
.visible-on .col-xs-6 .hidden-lg,
.hidden-on .col-xs-6 .hidden-xs,
.hidden-on .col-xs-6 .hidden-sm,
.hidden-on .col-xs-6 .hidden-md,
.hidden-on .col-xs-6 .hidden-lg {
  color: #999;
  border: 1px solid #ddd; }

.visible-on .col-xs-6 .visible-xs-block,
.visible-on .col-xs-6 .visible-sm-block,
.visible-on .col-xs-6 .visible-md-block,
.visible-on .col-xs-6 .visible-lg-block,
.hidden-on .col-xs-6 .visible-xs-block,
.hidden-on .col-xs-6 .visible-sm-block,
.hidden-on .col-xs-6 .visible-md-block,
.hidden-on .col-xs-6 .visible-lg-block {
  color: #468847;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6; }

/*
 * Glyphicons
 *
 * Special styles for displaying the icons and their classes in the docs.
 */
.bs-glyphicons {
  margin: 0 -10px 20px;
  overflow: hidden; }

.bs-glyphicons-list {
  padding-left: 0;
  list-style: none; }

.bs-glyphicons li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  font-size: 10px;
  line-height: 1.4;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #fff; }

.bs-glyphicons .glyphicon {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 24px; }

.bs-glyphicons .glyphicon-class {
  display: block;
  text-align: center;
  word-wrap: break-word;
  /* Help out IE10+ with class names */ }

.bs-glyphicons li:hover {
  color: #fff;
  background-color: #563d7c; }

@media (min-width: 768px) {
  .bs-glyphicons {
    margin-right: 0;
    margin-left: 0; }
  .bs-glyphicons li {
    width: 12.5%;
    font-size: 12px; } }

/*
 * Customizer
 *
 * Since this is so form control heavy, we have quite a few styles to customize
 * the display of inputs, headings, and more. Also included are all the download
 * buttons and actions.
 */
.bs-customizer .toggle {
  float: right;
  margin-top: 25px; }

/* Headings and form contrls */
.bs-customizer label {
  margin-top: 10px;
  font-weight: 500;
  color: #555; }

.bs-customizer h2 {
  padding-top: 30px;
  margin-top: 0;
  margin-bottom: 5px; }

.bs-customizer h3 {
  margin-bottom: 0; }

.bs-customizer h4 {
  margin-top: 15px;
  margin-bottom: 0; }

.bs-customizer .bs-callout h4 {
  margin-top: 0;
  /* lame, but due to specificity we have to duplicate */
  margin-bottom: 5px; }

.bs-customizer input[type="text"] {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  background-color: #fafafa; }

.bs-customizer .help-block {
  margin-bottom: 5px;
  font-size: 12px; }

/* For the variables, use regular weight */
#less-section label {
  font-weight: normal; }

/* Downloads */
.bs-customize-download .btn-outline {
  padding: 20px; }

/* Error handling */
.bs-customizer-alert {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding: 15px 0;
  color: #fff;
  background-color: #d9534f;
  border-bottom: 1px solid #b94441;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25); }

.bs-customizer-alert .close {
  margin-top: -4px;
  font-size: 24px; }

.bs-customizer-alert p {
  margin-bottom: 0; }

.bs-customizer-alert .glyphicon {
  margin-right: 5px; }

.bs-customizer-alert pre {
  margin: 10px 0 0;
  color: #fff;
  background-color: #a83c3a;
  border-color: #973634;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1); }

.bs-dropzone {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  color: #777;
  text-align: center;
  border: 2px dashed #eee;
  border-radius: 4px; }

.bs-dropzone .import-header {
  margin-bottom: 5px; }

.bs-dropzone .glyphicon-download-alt {
  font-size: 40px; }

.bs-dropzone hr {
  width: 100px; }

.bs-dropzone .lead {
  margin-bottom: 10px;
  font-weight: normal;
  color: #333; }

/*.bs-dropzone*/
#import-manual-trigger {
  cursor: pointer; }

.bs-dropzone p:last-child {
  margin-bottom: 0; }

/*
 * Brand guidelines
 *
 * Extra styles for displaying wordmarks, logos, etc.
 */
/* Logo series wrapper */
.bs-brand-logos {
  display: table;
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  color: #563d7c;
  background-color: #f9f9f9;
  border-radius: 4px; }

/* Individual items */
.bs-brand-item {
  padding: 60px 0;
  text-align: center; }

.bs-brand-item + .bs-brand-item {
  border-top: 1px solid #fff; }

.bs-brand-logos .inverse {
  color: #fff;
  background-color: #563d7c; }

/* Heading content within */
.bs-brand-item h1,
.bs-brand-item h3 {
  margin-top: 0;
  margin-bottom: 0; }

.bs-brand-item .bs-docs-booticon {
  margin-right: auto;
  margin-left: auto; }

/* Make the icons stand out on what is/isn't okay */
.bs-brand-item .glyphicon {
  width: 30px;
  height: 30px;
  margin: 10px auto -10px;
  line-height: 30px;
  color: #fff;
  border-radius: 50%; }

.bs-brand-item .glyphicon-ok {
  background-color: #5cb85c; }

.bs-brand-item .glyphicon-remove {
  background-color: #d9534f; }

@media (min-width: 768px) {
  .bs-brand-item {
    display: table-cell;
    width: 1%; }
  .bs-brand-item + .bs-brand-item {
    border-top: 0;
    border-left: 1px solid #fff; }
  .bs-brand-item h1 {
    font-size: 60px; } }

/*
 * ZeroClipboard styles
 */
.zero-clipboard {
  position: relative;
  display: none; }

.btn-clipboard {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 5px 8px;
  font-size: 12px;
  color: #767676;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e1e1e8;
  border-radius: 0 4px 0 4px; }

.btn-clipboard-hover {
  color: #fff;
  background-color: #563d7c;
  border-color: #563d7c; }

@media (min-width: 768px) {
  .zero-clipboard {
    display: block; }
  .bs-example + .zero-clipboard .btn-clipboard {
    top: -16px;
    border-top-right-radius: 0; } }

/*
 * AnchorJS Styles
 */
.anchorjs-link {
  color: inherit; }

@media (max-width: 480px) {
  .anchorjs-link {
    display: none; } }

*:hover > .anchorjs-link {
  opacity: .75;
  -webkit-transition: color .16s linear;
  transition: color .16s linear; }

*:hover > .anchorjs-link:hover,
.anchorjs-link:focus {
  text-decoration: none;
  opacity: 1; }

/*
 * Miscellaneous
 *
 * Odds and ends for optimum docs display.
 */
/* Pseudo :focus state for showing how it looks in the docs */
#focusedInput {
  border-color: #cccccc;
  /* Restate unfocused value to make CSSLint happy that there's a pre-CSS3 fallback*/
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  -webkit-box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: 0 0 8px rgba(82, 168, 236, 0.6); }

/* v4 notice above main navbar */
.v4-tease {
  display: block;
  padding: 15px 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background-color: #0275d8; }

.v4-tease:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0269c2; }

/* Nullify ill-advised printing of hrefs; see #18711 */
@media print {
  a[href]:after {
    content: "" !important; } }

.hll {
  background-color: #ffffcc; }

/*{ background: #f0f3f3; }*/
.c {
  color: #999; }

/* Comment */
.err {
  color: #AA0000;
  background-color: #FFAAAA; }

/* Error */
.k {
  color: #006699; }

/* Keyword */
.o {
  color: #555555; }

/* Operator */
.cm {
  color: #999; }

/* Comment.Multiline */
/* Edited to remove italics and make into comment */
.cp {
  color: #009999; }

/* Comment.Preproc */
.c1 {
  color: #999; }

/* Comment.Single */
.cs {
  color: #999; }

/* Comment.Special */
.gd {
  background-color: #FFCCCC;
  border: 1px solid #CC0000; }

/* Generic.Deleted */
.ge {
  font-style: italic; }

/* Generic.Emph */
.gr {
  color: #FF0000; }

/* Generic.Error */
.gh {
  color: #003300; }

/* Generic.Heading */
.gi {
  background-color: #CCFFCC;
  border: 1px solid #00CC00; }

/* Generic.Inserted */
.go {
  color: #AAAAAA; }

/* Generic.Output */
.gp {
  color: #000099; }

/* Generic.Prompt */
/* Generic.Strong */
.gu {
  color: #003300; }

/* Generic.Subheading */
.gt {
  color: #99CC66; }

/* Generic.Traceback */
.kc {
  color: #006699; }

/* Keyword.Constant */
.kd {
  color: #006699; }

/* Keyword.Declaration */
.kn {
  color: #006699; }

/* Keyword.Namespace */
.kp {
  color: #006699; }

/* Keyword.Pseudo */
.kr {
  color: #006699; }

/* Keyword.Reserved */
.kt {
  color: #007788; }

/* Keyword.Type */
.m {
  color: #FF6600; }

/* Literal.Number */
.s {
  color: #d44950; }

/* Literal.String */
.na {
  color: #4f9fcf; }

/* Name.Attribute */
.nb {
  color: #336666; }

/* Name.Builtin */
.nc {
  color: #00AA88; }

/* Name.Class */
.no {
  color: #336600; }

/* Name.Constant */
.nd {
  color: #9999FF; }

/* Name.Decorator */
.ni {
  color: #999999; }

/* Name.Entity */
.ne {
  color: #CC0000; }

/* Name.Exception */
.nf {
  color: #CC00FF; }

/* Name.Function */
.nl {
  color: #9999FF; }

/* Name.Label */
.nn {
  color: #00CCFF; }

/* Name.Namespace */
.nt {
  color: #2f6f9f; }

/* Name.Tag */
.nv {
  color: #003333; }

/* Name.Variable */
.ow {
  color: #000000; }

/* Operator.Word */
.w {
  color: #bbbbbb; }

/* Text.Whitespace */
.mf {
  color: #FF6600; }

/* Literal.Number.Float */
.mh {
  color: #FF6600; }

/* Literal.Number.Hex */
.mi {
  color: #FF6600; }

/* Literal.Number.Integer */
.mo {
  color: #FF6600; }

/* Literal.Number.Oct */
.sb {
  color: #CC3300; }

/* Literal.String.Backtick */
.sc {
  color: #CC3300; }

/* Literal.String.Char */
.sd {
  color: #CC3300;
  font-style: italic; }

/* Literal.String.Doc */
.s2 {
  color: #CC3300; }

/* Literal.String.Double */
.se {
  color: #CC3300; }

/* Literal.String.Escape */
.sh {
  color: #CC3300; }

/* Literal.String.Heredoc */
.si {
  color: #AA0000; }

/* Literal.String.Interpol */
.sx {
  color: #CC3300; }

/* Literal.String.Other */
.sr {
  color: #33AAAA; }

/* Literal.String.Regex */
.s1 {
  color: #CC3300; }

/* Literal.String.Single */
.ss {
  color: #FFCC33; }

/* Literal.String.Symbol */
.bp {
  color: #336666; }

/* Name.Builtin.Pseudo */
.vc {
  color: #003333; }

/* Name.Variable.Class */
.vg {
  color: #003333; }

/* Name.Variable.Global */
.vi {
  color: #003333; }

/* Name.Variable.Instance */
.il {
  color: #FF6600; }

/* Literal.Number.Integer.Long */
.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999; }

.highlight {
  background-color: #F8F8F8;
  padding: 0;
  border: 0; }
  .highlight pre {
    font-size: 14px;
    padding: 10px !important; }
  .highlight pre code {
    color: #FFFFFF; }

.bs-example + .highlight, .bs-example + .zero-clipboard + .highlight {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.full-width-layout .list-group .list-group-item {
  display: inline-block; }

@media only screen and (max-width: 767px) {
  .general-layout .list-group .list-group-item {
    display: inline-block; } }

@media only screen and (max-width: 991px) {
  .general-layout .list-group .list-group-item {
    display: inline-block; } }

header {
  min-height: 50px;
  background-color: #F8F8F8;
  margin: 0 -15px 25px -15px;
  padding: 15px 0; }

.logo {
  display: inline-block; }

.icon-list {
  font-size: 20px; }
